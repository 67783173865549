import Vue from 'vue';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import type { AxiosResponse } from 'axios';
import { parseData } from '@devhacker/shared/utils/parseData';
import {
  ADD_FAVORITE,
  CLEAR_USER_RELATED_DATA,
  GET_AND_SET_FAVORITES,
  REMOVE_FAVORITE,
  UPDATE_COMMENT_COUNTS,
  UPDATE_FAVORITES,
  UPDATE_VIEWS_COUNTS,
  FETCH_COMMENTS_COUNT,
  FETCH_VIEWS_COUNT,
  SET_CURRENT_TITLE, UPDATE_CURRENT_TITLE,
} from '~/constants';
import { getSubId } from '~/utils';
import { GetArticleViewsCountSchema } from '~/schemas/views';
import { FetchCommentsCountSchema } from '~/schemas/talker';

export const namespaced = false;

export interface ArticlesState {
  userRelated: {
    favorites: Array<IFavoriteItem>;
  };
  currentArticleTitle: string;
  commentsCount: {
    [propName: string]: number;
  };
  viewsCount: {
    [propName: string]: number;
  };
}

export const state = (): ArticlesState => ({
  userRelated: {
    favorites: [],
  },
  currentArticleTitle: '',
  commentsCount: {},
  viewsCount: {},
});

export const getters: GetterTree<ArticlesState, IRootState> = {
  getCommentsCount: (state: ArticlesState) => (postId: number): number => {
    return state.commentsCount[String(postId)] || 0;
  },

  currentArticleTitle: (state: ArticlesState) => () : string => {
    return state.currentArticleTitle;
  },

  getViewsCount: (state: ArticlesState) => (postId: number): number => {
    return Number(state.viewsCount[String(postId)]) || 0;
  },

  getArticleFavoriteId: (state: ArticlesState) => (postId: number) => {
    const found: IFavoriteItem | undefined =
          state.userRelated.favorites.find((f: IFavoriteItem) => f.post_id === postId);
    return found ? found.id : 0;
  },

  getArticleFavorite: (state: ArticlesState) => (postId: number) => {
    const found: IFavoriteItem | undefined =
          state.userRelated.favorites.find((f: IFavoriteItem) => f.post_id === postId);
    return found || null;
  },
};

type TFetchFavoritesArgs = {
  // articlesIds: Array<number>,
  params: any,
  isUpdating: boolean,
};

function onlyUnique (value: any, index: number, self: Array<any>) {
  return self.findIndex(item => item.id === value.id) === index;
}

export const actions: ActionTree<ArticlesState, IRootState> = {
  [GET_AND_SET_FAVORITES] (
    { commit, state },
    { params, isUpdating = true }: TFetchFavoritesArgs,
  ) {
    const subId = getSubId();
    if (subId) {
      return this.$authApi.fetchFavorites(params, subId)
        .then((resp: AxiosResponse) => {
          if (resp.status === 200) {
            const favorites = resp.data.data;
            const newValue = isUpdating ? favorites : [...favorites, ...state.userRelated.favorites].filter(onlyUnique);
            commit(UPDATE_FAVORITES, { favorites: newValue });
          }
          return (resp);
        });
      // .catch(() => {});
    }
  },
  [SET_CURRENT_TITLE] ({ commit }, payload) {
    commit(UPDATE_CURRENT_TITLE, payload);
  },
  async [FETCH_COMMENTS_COUNT] ({ commit }, payload) {
    const resp = await this.$commentsApi.fetchCommentsCount(payload);
    const parsedResponse = parseData(resp.data, FetchCommentsCountSchema);

    if (resp.status === 200) {
      commit(UPDATE_COMMENT_COUNTS, parsedResponse.data);
    }
  },
  async [FETCH_VIEWS_COUNT] ({ commit }, payload) {
    const resp = await this.$viewsApi.getArticleViewsCount(payload);
    const data = parseData(resp.data, GetArticleViewsCountSchema);
    if (resp.status === 200) {
      commit(UPDATE_VIEWS_COUNTS, data.viewsCounts);
    }
  },
};

export const mutations: MutationTree<ArticlesState> = {
  [UPDATE_FAVORITES] (state, { favorites }: { favorites: Array<IFavoriteItem> }) {
    Vue.set(state.userRelated, 'favorites', [...favorites, ...state.userRelated.favorites].filter(onlyUnique));
  },
  [UPDATE_CURRENT_TITLE] (state, title) {
    state.currentArticleTitle = title;
  },
  [ADD_FAVORITE] (state, { favorite }: { favorite: IFavoriteItem }) {
    state.userRelated.favorites = Object.seal([...state.userRelated.favorites, favorite]);
  },
  [REMOVE_FAVORITE] (state, { postId }: { postId: number }) {
    const newFavorites = state.userRelated.favorites.filter(favorite => favorite.post_id !== postId);
    state.userRelated.favorites = Object.seal(newFavorites);
  },

  [UPDATE_COMMENT_COUNTS] (state, commentCounts) {
    type CommentsCount = {
      post_ID: number;
      count: number;
    }
    commentCounts.forEach((item: CommentsCount): void => {
      Vue.set(state.commentsCount, item.post_ID, item.count);
    });
  },
  [CLEAR_USER_RELATED_DATA] (state) {
    state.userRelated = {
      favorites: [],
    };
  },
  [UPDATE_VIEWS_COUNTS] (state, viewsCounts: { [propName: string]: number }) {
    Vue.set(state, 'viewsCount', { ...state.viewsCount, ...viewsCounts });
  },
};
