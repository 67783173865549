import type { AxiosResponse } from 'axios';
import { Route } from 'vue-router';
import { stringify } from 'qs';
import type { Context } from '@nuxt/types';

import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { parseData } from '@devhacker/shared/utils/parseData';
import { FetchMenusResponse } from '../health/fetchMenus';
import { WORDPRESS_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';
import { paths as RESPONSE_TYPES } from '~/types/services/wordpress';
import { WidgetName } from '~/constants/widgets';
import { getApiEndpointDependingOnRoute, getParamsByRoute } from '~/utils/apiUtils';
import { fetchRecipesSchema } from '~/schemas/wordpress/fetchRecipesSchema';
import { FetchMenusSchema } from '~/schemas/health/health';

const URL_PREFIX = '/api/20/v1';

const PATHS = {
  HOME_POSTS: '/home-posts',
  AUTHOR: '/author',
  TOPICS: '/topics',
  TAGS: '/tags',
  STREAMS: '/streams',
  TOP: '/top',
  POSTS_BY_DATE: '/posts-by-date',
  SINGLE_POST: '/single-post',
  PREVIEW: '/preview',
  SEO: '/seo',
  MAIN_MENU: '/main-menu',
  SCRIPTS: '/scripts',
  FOOTERS: '/footers',
  MENUS: '/menus',
  WIDGETS: '/widgets',
  ADSFORM: '/ads-form',
};
const getUrl = (path: string, params: string = '', prefix: string = URL_PREFIX): string => {
  const removeFirstSlash = (value: string): string => value.startsWith('/') ? value.slice(1) : value;
  return `${prefix}${path}/${removeFirstSlash(params)}`;
};

type SubscribeToNewsletterResponseData = {
  success: true;
  data: {
    result: true
  },
} | {
  success: false,
  data: {
    is_error: true,
    message: string,
  }
}

type TFetchRecipes = {
  recipes: RecipeFromServer[];
  title?: string;
  totalPage?: number;
  totalRecipes?: number;
  currentPage?: number;
  isUserLoggedIn?: boolean;
  taxonomies?: {
    cuisines?: number[];
    dishTypes?: number[];
    cookingMethods?: number[];
    ingredients?: number[];
    keywords?: number[];
  };
  seo?: RecipesSeo;
};

export interface IWordpressApi {
  fetchArticles: (route: Route, page: number, limit?: number) => Promise<AxiosResponse>;
  fetchHeadScriptsFromServer: (path: string) => Promise<AxiosResponse>;
  fetchSeo: (routePath: string, page: number, limit: number) => Promise<AxiosResponse>;
  fetchAppBoxData: (articleId: number, appUrl: string) => Promise<AxiosResponse>;
  fetchWidgets: (includes: WidgetName[]) => Promise<AxiosResponse<RESPONSE_TYPES['/widgets']['get']['responses']['200']['content']['application/json']>>;
  sendTypoData: (data: { action: string }) => Promise<AxiosResponse>;
  fetchRecommendedArticles: (articleId: number, limit: number) => Promise<AxiosResponse<{
    items: IArticle[];
  }>>;
  subscribeToNewsletter: (action: string, type: string, email: string) => Promise<AxiosResponse<SubscribeToNewsletterResponseData>>;
  fetchRecipesCount: (pathname: string) => Promise<AxiosResponse>;
  fetchFilterMenus: () => Promise<AxiosResponse>;
  fetchMenus: () => Promise<FetchMenusResponse>;
  fetchFilterSettings: (pathname: string) => Promise<AxiosResponse>;
  searchIngredients: (searchValue: string) => Promise<AxiosResponse>;
  fetchRecipes: (page: number, pathname: string, limit?: number) => Promise<TFetchRecipes>
  fetchRecipe: (pathname: string) => Promise<AxiosResponse<FullRecipeFromServer>>;
  fetchRecipePreview: ((id: number, type: 'public-recipe-preview' | 'admin-recipe-preview', hash?: string) => Promise<AxiosResponse<{
    data: {
      recipe: Omit<FullRecipeFromServer, 'url' | 'commentsEnabled'>;
      commentsEnabled: boolean;
    }
  }>>);
  fetchRecipeWidgets: (pathname: string, includes: WidgetName[]) => Promise<AxiosResponse<{
    'recipes-above-header': WidgetInRecipes[];
    'recipes-below-header': WidgetInRecipes[];
    'recipes-list-sidebar': WidgetInRecipes[];
    'recipe-sidebar': WidgetInRecipes[];
    'recipes-above-comments': WidgetInRecipes[];
    'recipes-below-comments': WidgetInRecipes[];
    'recipes-below-ingredients': WidgetInRecipes[];
    'recipes-between-posts': WidgetInRecipes[];
    visibilityData?: RecipesVisibilityData;
  }>>;
  fetchRecipeScripts: () => Promise<AxiosResponse<Array<TWidget>>>;
}

type Params = {
  $axios: Context['$axios'],
  $logger: Context['$logger'],
  $sentry: Context['$sentry'],
  version: string,
}
export default ({ $axios, $logger, version }: Params): IWordpressApi => {
  const wordpressRequest = () => {
    const axiosInstance = $axios.create({
      baseURL: WORDPRESS_API_URL,
      timeout: TIMEOUT,
    });

    attachLoggerToAxios(axiosInstance, $logger, 'wordpress');

    return axiosInstance;
  };

  const defaultParams = version.length ? { version } : {};

  const getParams = (params: any = {}) => ({ ...defaultParams, ...params });

  return {
    fetchArticles: (route, page, limit = 30) => {
      const url = getUrl(getApiEndpointDependingOnRoute(route, PATHS));
      const params = getParams({
        page,
        limit,
        ...getParamsByRoute(route),
      });

      return wordpressRequest().get(url, { params });
    },

    fetchSeo: (routePath, page, limit) => {
      const url = getUrl(PATHS.SEO, routePath);
      const params = getParams({ page, limit });
      return wordpressRequest().get(url, { params });
    },

    fetchHeadScriptsFromServer: (path) => {
      const url = getUrl(PATHS.SCRIPTS);
      return wordpressRequest().get(url, { params: getParams({ path }) });
    },

    fetchAppBoxData: (postId, appUrl) => {
      const url = '/wp-admin/admin-ajax.php?action=refresh-appbox';
      return wordpressRequest().post(url, {
        postId,
        url: appUrl,
      });
    },

    // * valibot
    fetchMenus () {
      const url = getUrl(PATHS.MENUS);
      return wordpressRequest().get(url)
        .then(({ data }) => parseData(data, FetchMenusSchema));
    },

    sendTypoData (data) {
      const url = '/wp-admin/admin-ajax.php';
      const t = wordpressRequest().post(url, stringify(data));
      return t;
    },

    fetchWidgets (includes: WidgetName[]) {
      const url = getUrl(PATHS.WIDGETS);
      return wordpressRequest().get(url, {
        params: getParams({
          includes: includes.join(','),
        }),
      },
      );
    },

    fetchRecommendedArticles: (articleId, limit): Promise<AxiosResponse> => {
      const url = `/api/20/v1/recommended-posts/${articleId}`;
      return wordpressRequest().get(url, { params: getParams({ limit }) });
    },

    subscribeToNewsletter (action, type, email) {
      const url = '/wp-admin/admin-ajax.php';
      const data = stringify({
        action,
        type,
        email,
        agree: true,
      });
      return wordpressRequest().post(url, data);
    },

    fetchRecipesCount: (pathname) => {
      const url = `/api/recipes/v1/query${pathname}?count`;
      return wordpressRequest().get(url);
    },

    fetchFilterMenus: () => {
      const url = '/api/recipes/v1/filter/menu';
      return wordpressRequest().get(url);
    },

    fetchFilterSettings: (pathname) => {
      const url = `/api/recipes/v1/filter/route${pathname}`;
      return wordpressRequest().get(url);
    },

    searchIngredients: (searchValue) => {
      const url = `/api/recipes/v1/filter/ingredients/${searchValue}`;
      return wordpressRequest().get(url);
    },

    // * valibot
    fetchRecipes: async (page, pathname, limit = 25) => {
      // encode на случай если путь содержин латиницу
      const url = encodeURI(`/api/recipes/v1/query${pathname}`);
      const response = await wordpressRequest().get(url, {
        params: getParams({
          page,
          limit,
        }),
      });

      const parsedResponse = parseData(response.data, fetchRecipesSchema) as TFetchRecipes;

      return parsedResponse;
    },

    fetchRecipe: (pathname) => {
      const url = `/api/recipes/v1/recipe/${pathname}`;
      return wordpressRequest().get(url);
    },

    fetchRecipePreview: (id, type, hash) => {
      const url = `/api/recipes/v1/query/recipe-preview/${id}`;
      return wordpressRequest().get(url, {
        params: getParams({
          preview_type: type,
          ...(hash ? {
            preview_type_hash: hash,
          } : {}),
        }),
      });
    },

    // * valibot
    fetchRecipeWidgets: (pathname, includes) => {
      const url = '/api/recipes/v1/widgets';
      return wordpressRequest().get(url, {
        params: getParams({
          pathname,
          includes: includes.join(','),
        }),
      });
    },

    fetchRecipeScripts: () => {
      const url = '/api/recipes/v1/scripts/';
      return wordpressRequest().get(url);
    },
  };
};
