import { TDelayedActionsActions } from '~/types/store/delayed-actions/actions';
import { SET_DELAYED_ACTION_ACTION, REMOVE_DELAYED_ACTION_ACTION } from '~/constants/store/delayed-actions/actions';
import { SET_DELAYED_ACTION_MUTATION, REMOVE_DELAYED_ACTION_MUTATION } from '~/constants/store/delayed-actions/mutations';

const actions: TDelayedActionsActions = {
  [SET_DELAYED_ACTION_ACTION] (store, action) {
    store.commit(SET_DELAYED_ACTION_MUTATION, action);
  },
  [REMOVE_DELAYED_ACTION_ACTION] (store, actionName) {
    store.commit(REMOVE_DELAYED_ACTION_MUTATION, actionName);
  },
};

export default actions;
