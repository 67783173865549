import { TDelayedActionsMutations } from '~/types/store/delayed-actions/mutations';
import { SET_DELAYED_ACTION_MUTATION, REMOVE_DELAYED_ACTION_MUTATION } from '~/constants/store/delayed-actions/mutations';

const mutations: TDelayedActionsMutations = {
  [SET_DELAYED_ACTION_MUTATION] (state, action) {
    state.actions[action.name] = action.action;
  },
  [REMOVE_DELAYED_ACTION_MUTATION] (state, actionName) {
    if (actionName in state.actions) {
      delete state.actions[actionName];
    }
  },
};

export default mutations;
