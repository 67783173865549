import type { Context } from '@nuxt/types';
import type { AxiosResponse } from 'axios';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { TIMEOUT } from '~/constants';

const baseURL = 'https://recs.s5o.ru';

export interface ISportsApi {
    sendDataSports: (sendData: any) => Promise<AxiosResponse>;
}
type Params = {
  $axios: Context['$axios'],
  $logger: Context['$logger'],
}
export default ({ $axios, $logger }: Params): ISportsApi => {
  const sportsRequest = (): NuxtAxiosInstance => {
    const axiosInstance = $axios.create({
      baseURL,
      timeout: TIMEOUT,
    }) as NuxtAxiosInstance;

    attachLoggerToAxios(axiosInstance, $logger, 'sports');

    return axiosInstance;
  };

  return {
    sendDataSports: (sendData: string) => {
      const url = '/send/';
      return sportsRequest().post(url, sendData);
    },
  };
};
