import type { AxiosResponse } from 'axios';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { Context } from '@nuxt/types';
import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { SPECIAL_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';

export interface ISpecialApi {
    askQuestion: (user_id: number, name: string, text: string) => Promise<AxiosResponse>;
}

type Params = {
  $axios: Context['$axios'],
  $logger: Context['$logger'],
  $sentry: Context['$sentry'],
}
export default ({ $axios, $logger, $sentry }: Params): ISpecialApi => {
  const specialRequest = (): NuxtAxiosInstance => {
    const axiosInstance = $axios.create({
      baseURL: SPECIAL_API_URL,
      timeout: TIMEOUT,
    }) as NuxtAxiosInstance;

    axiosInstance.onError($sentry.captureException);

    attachLoggerToAxios(axiosInstance, $logger, 'special');

    return axiosInstance;
  };

  return {
    askQuestion: (user_id, name, text) => {
      const url = '/api/asklh/question-post';
      return specialRequest().post(url, { user_id, name, text });
    },
  };
};
