import type { AxiosResponse } from 'axios';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { Context } from '@nuxt/types';
import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { TALKER_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';

export interface ITalkerApi {
  fetchLastComments: () => Promise<AxiosResponse>;
  fetchCommentsCount: (postIds: Array<number>) => Promise<AxiosResponse>;
  fetchComments: (id: number, limit: number, sortType: 'trending' | 'oldest') => Promise<AxiosResponse>;
  fetchAmpComments: (id: number, sortType: 'trending' | 'oldest') => Promise<AxiosResponse>;
}
type Params = {
  $axios: Context['$axios'],
  $logger: Context['$logger'],
  $sentry: Context['$sentry'],
}
export default ({ $axios, $logger, $sentry }: Params): ITalkerApi => {
  const talkerRequest = (): NuxtAxiosInstance => {
    const headers = {
      'Content-Type': 'application/json',
      'x-app': 'lifehacker-web',
    };

    const axiosInstance = $axios.create({
      headers,
      baseURL: TALKER_API_URL,
      timeout: TIMEOUT,
    }) as NuxtAxiosInstance;

    axiosInstance.onError($sentry.captureException);

    attachLoggerToAxios(axiosInstance, $logger, 'talker');
    return axiosInstance;
  };

  return {
    fetchComments: (postId, limit = 4, sortType = 'trending') => {
      const url = `/api/v2/comments/post/${postId}?sort=${sortType}&format_ver=1&limit=${limit}`;
      return talkerRequest().get(url);
    },
    fetchAmpComments: (postId, sortType = 'trending') => {
      const url = `/api/v2/comments/post/${postId}?sort=${sortType}&format_ver=1`;
      return talkerRequest().get(url);
    },

    fetchLastComments: (limit = 4) => {
      const url = `/api/comments/last?limit=${limit}`;
      return talkerRequest().get(url);
    },

    fetchCommentsCount: (postIds) => {
      const url = '/api/comments/count';
      return talkerRequest()
        .get(
          url,
          {
            params: { post_ids: postIds.join() },
          },
        );
    },
  };
};
