import type { AxiosResponse } from 'axios';
import { BrowserInfo } from 'detect-browser';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { Context } from '@nuxt/types';
import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { PUSH_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';
import { ISubscription } from '~/plugins/push-notifications.client';

export interface IPushApi {
  addSubscription: (
    { token, type, auth, p256dh }: ISubscription,
    { name, version, os }: BrowserInfo
  ) => Promise<AxiosResponse>;
  subscribe: (token: string) => Promise<AxiosResponse<{success: boolean}>>;
  unsubscribe: (token: string) => Promise<AxiosResponse>;
  getDeviceInfo: (token: string) => Promise<AxiosResponse>;
  sendTestPush: (token: string) => Promise<AxiosResponse>;
}

type Params = {
  $axios: Context['$axios'],
  $logger: Context['$logger'],
  $sentry: Context['$sentry'],
}

export default ({ $axios, $sentry, $logger }: Params): IPushApi => {
  const pushRequest = (): NuxtAxiosInstance => {
    const axiosInstance = $axios.create({
      baseURL: PUSH_API_URL,
      timeout: TIMEOUT,
    }) as NuxtAxiosInstance;
    axiosInstance.onError($sentry.captureException);

    attachLoggerToAxios(axiosInstance, $logger, 'push');
    return axiosInstance;
  };

  return {
    addSubscription: (
      { token, type, auth, p256dh },
      { name, version, os },
    ) => {
      const url = '/api/add-sub';
      return pushRequest().post(url, {
        token,
        type,
        auth,
        p256dh,
        user_agent: `${name} ${version} ${os}`,
      });
    },

    subscribe: (token) => {
      const url = '/api/subscribe';
      return pushRequest().post(url, { token });
    },

    unsubscribe: (token) => {
      const url = '/api/unsubscribe';
      return pushRequest().post(url, { token });
    },

    getDeviceInfo: (token) => {
      const url = '/api/get-device';
      return pushRequest().get(url, { params: { token } });
    },

    sendTestPush: (token) => {
      const url = '/api/send-to-token';
      return pushRequest().post(url, {
        token,
        title: document.title || 'test title',
        body: document.title || 'test body',
        wp_post_id: 1,
        link: 'https://lifehacker.ru/kak-ukrepit-zdorove/?utm_source=site&utm_campaign=push_desktop&utm_medium=push',
        picture: 'https://i.imgur.com/PQmkAEU.png',
      });
    },
  };
};
