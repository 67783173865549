import type { AxiosResponse } from 'axios';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { Context } from '@nuxt/types';
import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { FULL_SEARCH_API_URL, WORDPRESS_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';

export interface ISearchApi {
  getSearchItemsByIds: (includes: Array<number>) => Promise<AxiosResponse>;
};

type SearchApi = 'wp' | 'search';

function getBaseUrl (api: SearchApi) {
  switch (api) {
    case 'wp':
      return WORDPRESS_API_URL;
    case 'search':
      return FULL_SEARCH_API_URL;
    default:
      return FULL_SEARCH_API_URL;
  }
}

type Params = {
  $axios: Context['$axios'],
  $logger: Context['$logger'],
  $sentry: Context['$sentry'],
}
export default ({ $axios, $sentry, $logger }: Params): ISearchApi => {
  const searchRequest = (api: SearchApi): NuxtAxiosInstance => {
    const axiosInstance = $axios.create({
      baseURL: getBaseUrl(api),
      timeout: TIMEOUT,
    }) as NuxtAxiosInstance;

    axiosInstance.onError($sentry.captureException);

    attachLoggerToAxios(axiosInstance, $logger, 'search');
    return axiosInstance;
  };

  return {
    getSearchItemsByIds: (includes: Array<number>) => {
      const url = '/fullsearch/solr/postId';
      const params = {
        includes: includes.join(),
        page: 1,
        limit: includes.length,
      } as any;

      return searchRequest('search').get(url, { params });
    },
  };
};
