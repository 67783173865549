// Переменные для рекламы из adfox.
// Должны добавляться в window.
import Vue from 'vue';
import { MutationTree, GetterTree } from 'vuex';
import { ADD_ADFOX_PARAMS_BY_PATH, ADD_ADFOX_IP_V6 } from '~/constants';
import { isHomePage } from '~/router';

export const namespaced = false;

export type AdfoxParams = {
  lh_adfox_homepage: boolean;
  lh_adfox_rtn: boolean;
  lh_adfox_noad: string;
  lh_adfox_noadsense: string;
  lh_adfox_pk: string;
  lh_adfox_postid: string;
  lh_adfox_streams: string;
  lh_adfox_tags: string;
  lh_adfox_terms: string;
}

export type AdfoxParamsWithIp = AdfoxParams & {
  // просьба со стороны рекламы передавать именно 1 или 0
  lh_adfox_ipv6: 1 | 0;
}

const defaultAdfox: AdfoxParams = {
  lh_adfox_homepage: false,
  lh_adfox_rtn: false,
  lh_adfox_noad: '',
  lh_adfox_noadsense: '',
  lh_adfox_pk: '',
  lh_adfox_postid: '',
  lh_adfox_streams: '',
  lh_adfox_tags: '',
  lh_adfox_terms: '',
};

type AdfoxParamsState = {
  isIpV6: boolean,
  byPath: {
    [path: string]: AdfoxParams;
  };
};
export const state = (): AdfoxParamsState => ({
  isIpV6: false,
  byPath: {},
});

export const getters: GetterTree<AdfoxParamsState, IRootState> = {
  currentAdfox (state, _getters, { route }): AdfoxParamsWithIp {
    const currentPath = route.fullPath.replace(route.hash, '');
    const currentData = state.byPath[String(currentPath)] ?? defaultAdfox;

    // По умолчанию lh_adfox_homepage выключен чтобы не было ложно позитивных для пустого
    // adfoxParams, добавляем если на главной странице в геттере
    if (isHomePage(route.name)) {
      currentData.lh_adfox_homepage = true;
    }

    return { ...currentData, lh_adfox_ipv6: state.isIpV6 ? 1 : 0 };
  },
};

export const mutations: MutationTree<AdfoxParamsState> = {
  [ADD_ADFOX_IP_V6] (state, isIpV6: boolean) {
    state.isIpV6 = isIpV6;
  },
  [ADD_ADFOX_PARAMS_BY_PATH] (state, { path, adfoxParams }: { path: string, adfoxParams: AdfoxParams }) {
    Vue.set(state.byPath, path, Object.freeze(adfoxParams));
  },
};
