import type { AxiosResponse } from 'axios';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { Context } from '@nuxt/types';
import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { REACTIONS_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';
import { authErrorHandler } from '~/utils/errorHandlerAuth';
import { authRequestInterceptor } from '~/utils/interceptorsRequestAuth';

export interface ReactionsApi {
  fetchReactions: (ids: number[]) => Promise<AxiosResponse<{
    data: ReactionFromServer[];
    success: boolean;
  }>>;
  updateReaction: (id: number, user_state: TReactionType, isHealth: boolean) => Promise<AxiosResponse<{
    data: ReactionFromServer;
    success: boolean;
  }>>;
  updateLike: (id: number) => Promise<AxiosResponse<{
    data: ReactionFromServer;
    success: boolean;
  }>>;
  updateDislike: (id: number) => Promise<AxiosResponse<{
    data: ReactionFromServer;
    success: boolean;
  }>>;
}

type Params = {
  $axios: Context['$axios'],
  $logger: Context['$logger'],
  $sentry: Context['$sentry'],
  store: Context['store'],
  redirect: Context['redirect'],
  route: Context['route'],
}

export default (ctx: Params): ReactionsApi => {
  const { $axios, $logger, $sentry } = ctx;

  const reactionRequest = (): NuxtAxiosInstance => {
    const axiosInstance = $axios.create({
      baseURL: REACTIONS_API_URL,
      timeout: TIMEOUT,
    }) as NuxtAxiosInstance;

    let XUserId = 0;

    if (process.client) {
      try {
        XUserId = JSON.parse(localStorage.getItem('XUserId') || '0');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    attachLoggerToAxios(axiosInstance, $logger, 'reactions');

    axiosInstance.interceptors.request.use(
      // @ts-ignore
      async (config) => {
        await authRequestInterceptor(config, ctx);
        config.headers['X-UserId'] = XUserId;
        return config;
      },
      function (error) {
        authErrorHandler(error, ctx);
        // eslint-disable-next-line no-console
        console.warn('error', error);
        return Promise.reject(error);
      },
    );

    axiosInstance.interceptors.response.use(
      response => response,
      (error) => {
        authErrorHandler(error, ctx);
        return Promise.reject(error);
      });

    axiosInstance.onError($sentry.captureException);

    return axiosInstance;
  };

  return {
    fetchReactions: (ids) => {
      const url = '/api/v1/posts';
      return reactionRequest().get(url, {
        params: {
          post_ids: ids.join(),
        },
      });
    },
    updateReaction: (post_id: number, user_state: TReactionType, isHealth: boolean = false) => {
      const url = '/api/v1/posts';
      return reactionRequest().post(url, {
        post_id,
        user_state,
        ...(isHealth && { post_type: 'health' }),
      });
    },
    // используется только в рецептах
    updateLike: (id: number) => {
      const url = '/api/v1/posts';
      return reactionRequest().post(url, {
        post_id: id,
        user_state: 'like',
      });
    },
    // используется только в рецептах
    updateDislike: (id: number) => {
      const url = '/api/v1/posts';
      return reactionRequest().post(url, {
        post_id: id,
        user_state: 'dislike',
      });
    },
  };
};
