import type { AxiosResponse } from 'axios';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { Context } from '@nuxt/types';
import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { WORDPRESS_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';
import { MascotMenu } from '~/types/store/mascot';

interface MascotMenus {
  items: {
    footer: MascotMenu;
    burger: MascotMenu;
  };
}

export interface IMascotApi {
  fetchMascotInfo: () => Promise<AxiosResponse<MascotMenus>>;
}

const URL_PREFIX = 'api/20/v1';

type Params = {
  $axios: Context['$axios'],
  $logger: Context['$logger'],
  $sentry: Context['$sentry'],
}
export default ({ $axios, $logger, $sentry }: Params): IMascotApi => {
  const mascotRequest = (): NuxtAxiosInstance => {
    const axiosInstance = $axios.create({
      baseURL: `${WORDPRESS_API_URL}/${URL_PREFIX}`,
      timeout: TIMEOUT,
    }) as NuxtAxiosInstance;

    axiosInstance.onError($sentry.captureException);

    attachLoggerToAxios(axiosInstance, $logger, 'mascot');

    return axiosInstance;
  };

  return {
    fetchMascotInfo: () => {
      return mascotRequest().get('/mascot');
    },
  };
};
