import { AxiosResponse } from 'axios';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import type { Context } from '@nuxt/types';
import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { AUTH_API_URL, PUSHER_API_URL } from '~/constants/config';
import { TIMEOUT } from '~/constants';
import { components } from '~/types/services/auth';
import { authErrorHandler } from '@/utils/errorHandlerAuth';
import { getAccessToken } from '~/utils';
import { authRequestInterceptor } from '~/utils/interceptorsRequestAuth';

type ApiParams = {
  page?: number;
  limit?: number;
  post_ids?: string;
  filter__is_viewed?: number;
}
export interface IAuthApi {
  fetchFavorites: (params: ApiParams, integerId: number) => Promise<AxiosResponse>;
  fetchFolders: (integerId: number, accessToken: string) => Promise<AxiosResponse>;
  addFolder: (name: string, integerId: number, accessToken: string) => Promise<AxiosResponse>;
  deleteFolder: (folderId: number, integerId: number, accessToken: string) => Promise<AxiosResponse>,
  renameFolder: (payload: any, integerId: number, accessToken: string) => Promise<AxiosResponse>,

  patchFavorite: (favoriteId: number, folderId: number, integerId: number, accessToken: string) => Promise<AxiosResponse>;
  getDefaultFavorites: (integerId: number, accessToken: string) => Promise<AxiosResponse>;
  addArticleToFavorites: (postId: number, folderId: number, integerId: number, accessToken: string) => Promise<AxiosResponse>;
  removeArticleFromFavorites: (favoriteId: number, integerId: number, accessToken: string) => Promise<AxiosResponse>;
  getUserData: (accessToken: string, integerId: number) => Promise<AxiosResponse>;

  patchUserProperty: (integerId: number, payload: any) => Promise<AxiosResponse>;

  removeUserSocialAccount: (accessToken: string, integerId: number, accountName: string) => Promise<AxiosResponse>;

  removeUserAccount: (accessToken: string, integerId: number, payload: any) => Promise<any>;

  fetchPublicUserData: (integerId: string) => Promise<AxiosResponse<{
    data: components['schemas']['UserPublic']
  }>>;
  fetchPublicUserComments: (integerId: string, params: ApiParams) => Promise<AxiosResponse<{
    data?: CommentItem[],
    meta?: components['schemas']['Meta'],
  }>>;
  fetchPublicUserPosts: (integerId: string, params: ApiParams) => Promise<AxiosResponse<{
    data: IArticle[],
    meta?: components['schemas']['LhBlogMeta'],
    success: boolean,
  }>>;
  fetchNotifications: (accessToken: string, integerId: string, params: ApiParams) => Promise<AxiosResponse>;
  resetNotifications: (integerId: number) => Promise<AxiosResponse>;
  authGoogleOneTap: (payload: any) => Promise<AxiosResponse>
  fetchUserLocation: () => Promise<AxiosResponse>
  logout: () => Promise<any>
}
type Params = {
  $axios: Context['$axios'],
  $logger: Context['$logger'],
  $sentry: Context['$sentry'],
  redirect: Context['redirect'],
  store: Context['store'],
  route: Context['route'],
}
export default (ctx : Params): IAuthApi => {
  const { $axios, $logger } = ctx;

  const authRequest = (withInterceptor = true): NuxtAxiosInstance => {
    const axiosInstance = $axios.create({
      baseURL: AUTH_API_URL,
      timeout: TIMEOUT,
      withCredentials: true,
    }) as NuxtAxiosInstance;

    attachLoggerToAxios(axiosInstance, $logger, 'auth');

    withInterceptor && axiosInstance.interceptors.request.use(
      // @ts-ignore
      async (config) => {
        await authRequestInterceptor(config, ctx);
        return config;
      },
      function (error) {
        authErrorHandler(error, ctx);
        // eslint-disable-next-line no-console
        console.warn('error', error);
        return Promise.reject(error);
      },
    );

    axiosInstance.interceptors.response.use(
      response => response,
      (error) => {
        authErrorHandler(error, ctx);
        return Promise.reject(error);
      });

    // axiosInstance.onError($sentry.captureException);

    return axiosInstance;
  };

  const pusherRequest = (): NuxtAxiosInstance => {
    const axiosInstance = $axios.create({
      baseURL: PUSHER_API_URL,
      timeout: TIMEOUT,
    }) as NuxtAxiosInstance;

    attachLoggerToAxios(axiosInstance, $logger, 'pusher');

    axiosInstance.interceptors.request.use(
      // @ts-ignore
      async (config) => {
        await authRequestInterceptor(config, ctx);
        return config;
      },
      function (error) {
        authErrorHandler(error, ctx, 'auth.ts pusher request');
        // eslint-disable-next-line no-console
        console.warn('error', error);
        return Promise.reject(error);
      },
    );

    axiosInstance.interceptors.response.use(
      response => response,
      (error) => {
        authErrorHandler(error, ctx);
        return Promise.reject(error);
      });

    // axiosInstance.onError($sentry.captureException);

    return axiosInstance;
  };

  return {
    fetchFavorites: (params, integerId) => {
      const url = `/api/v1/users/${integerId}/favorites`;

      return authRequest().get(url, {
        params: {
          ...params,
        },
      });
    },

    fetchFolders: (integerId: number, _accessToken: string): Promise<AxiosResponse> => {
      const url = `api/v1/users/${integerId}/folders`;
      // по умолчанию запрашиваем 100 папок
      // TO DO: переделать на получение партиями
      // по 10 штук (или по количеству умещаемуся в выпадающем меню)
      return authRequest().get(url, {
        params: {
          page: 0,
          limit: 100,
        },
      });
    },

    // добавить новую папку
    addFolder: (name: string, integerId: number, _accessToken: string): Promise<AxiosResponse> => {
      const url = `api/v1/users/${integerId}/folders`;
      return authRequest().post(url, { name });
    },

    // удалить папку
    deleteFolder: (folderId: number, integerId: number, _accessToken: string): Promise<AxiosResponse> => {
      const url = `/api/v1/users/${integerId}/folders/${folderId}`;
      return authRequest().delete(url);
    },

    // переименовать папку
    renameFolder: ({ name, folderId }, integerId: number, _accessToken: string): Promise<AxiosResponse> => {
      const url = `/api/v1/users/${integerId}/folders/${folderId}`;
      return authRequest().patch(url, { name });
    },

    // перенести избранное в другую папку
    patchFavorite: (favoriteId: number, folderId: number, integerId: number, _accessToken: string): Promise<AxiosResponse> => {
      const url = `/api/v1/users/${integerId}/favorites/${favoriteId}`;
      return authRequest().patch(url, { folderId });
    },

    // получить избранные статьи, находящиеся вне папки
    getDefaultFavorites: (integerId: number, _accessToken: string): Promise<AxiosResponse> => {
      const url = `api/v1/users/${integerId}/favorites`;
      return authRequest().get(url, {
        params: {
          folder_ids: 0,
        },
      });
    },

    addArticleToFavorites: (postId: number, folderId: number = 0, integerId: number, _accessToken: string): Promise<AxiosResponse> => {
      const url = `/api/v1/users/${integerId}/favorites`;
      return authRequest().post(url, { postId, folderId });
    },

    removeArticleFromFavorites: (favoriteId, integerId, _accessToken) => {
      const url = `/api/v1/users/${integerId}/favorites/${favoriteId}`;
      return authRequest().delete(url);
    },

    getUserData: (_accessToken, subId) => {
      const url = `/api/v1/users/${subId}`;
      return authRequest().patch(url);
    },

    patchUserProperty: (integerId, payload) => {
      const url = `/api/v1/users/${integerId}`;

      return authRequest().patch(url, {
        [payload.property]: payload.value,
      });
    },

    removeUserSocialAccount: (_accessToken, integerId, accountName) => {
      const url = `/api/v1/users/${integerId}/${accountName}/remove`;
      return authRequest().patch(url);
    },

    removeUserAccount: (_accessToken, integerId, payload) => {
      const url = `/api/v1/users/${integerId}/archive/push`;
      return authRequest().patch(url, { reason_code: payload.code, reason_comment: payload.comment });
    },

    fetchPublicUserData: (integerId) => {
      const url = `/api/v1/public/users/${integerId}`;
      return authRequest(false).get(url);
    },

    fetchPublicUserComments: (integerId, params) => {
      const url = `/api/v1/public/users/${integerId}/comments`;
      return authRequest(false).get(url, { params });
    },

    fetchPublicUserPosts: (integerId, params) => {
      const url = `/api/v1/public/users/${integerId}/posts`;
      return authRequest(false).get(url, { params });
    },

    fetchNotifications: (_accessToken, integerId, params) => {
      const url = `/api/v1/kolokolchick/user/${integerId}`;
      return pusherRequest().get(url, { params });
    },

    resetNotifications: (integerId) => {
      const url = `/api/v1/kolokolchick/user/${integerId}/notifications/mass-viewing`;
      return pusherRequest().patch(url);
    },

    authGoogleOneTap: (payload: any) => {
      const url = '/id/auth/google/callback';

      return $axios({
        method: 'get',
        url,
        baseURL: AUTH_API_URL,
        timeout: TIMEOUT,
        params: {
          ...payload,
        },
      });
    },

    fetchUserLocation () {
      return authRequest().get('api/v1/auth/location');
    },

    logout: async () => {
      const accessToken = getAccessToken();

      if (accessToken) {
        try {
          const axiosInstance = $axios.create({
            baseURL: `${AUTH_API_URL}`,
            withCredentials: true,
            timeout: TIMEOUT,
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'x-auth-app': 'web',
            },
          });

          attachLoggerToAxios(axiosInstance, $logger, 'logout');

          const response = await axiosInstance.patch('/api/v1/auth/revoke-token');
          return response;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          return `auth.ts logout: ${err}`;
        }
      }
      // eslint-disable-next-line no-console
      console.error('Токена не существует');
    },
  };
};
