import type { AxiosResponse } from 'axios';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import qs from 'qs';
import type { Context } from '@nuxt/types';
import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { TIMEOUT } from '~/constants';
import { VIEWS_API_URL, WORDPRESS_API_URL } from '~/constants/config';

export interface IViewsApi {
  getArticleViewsCount: (articleIds: number[]) => Promise<AxiosResponse>;
  incrArticleViewsCount: (articleId: number) => Promise<AxiosResponse>;
  syncArticleViewsCount: (articleId: number) => Promise<AxiosResponse<{
    viewsCount: number;
  }>>;
}

type Params = {
  $axios: Context['$axios'],
  $logger: Context['$logger'],
  $sentry: Context['$sentry'],
}
export default ({ $axios, $logger }: Params): IViewsApi => {
  // Эта апишка ругается на CORS при локальной разработке
  // она на писана на GO и беки боятся ее менять
  const viewsRequest = (baseURL: string, timeout: number = TIMEOUT): NuxtAxiosInstance => {
    const axiosInstance = $axios.create({
      baseURL,
      timeout,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: '*/*',
      },
    }) as NuxtAxiosInstance;

    attachLoggerToAxios(axiosInstance, $logger, 'views');

    return axiosInstance;
  };

  return {
    getArticleViewsCount: (articleIds) => {
      const url = '/get/';

      return viewsRequest(VIEWS_API_URL).post(url, qs.stringify({
        action: 'get-views',
        postIds: articleIds.join(),
      }));
    },

    incrArticleViewsCount: (articleId) => {
      const url = '/incr/';
      return viewsRequest(VIEWS_API_URL).post(url, qs.stringify({
        action: 'increase-views',
        postId: articleId,
      }));
    },

    syncArticleViewsCount: (articleId) => {
      const url = '/wp-admin/admin-ajax.php';
      // хардкод, потому что нет prod/stage
      return viewsRequest(WORDPRESS_API_URL, 60000).post(url, qs.stringify({
        action: 'sync_views',
        postId: articleId,
      }));
    },
  };
};
